import { ETrainingTypes } from '@/enums';
import { NullQuery } from '@/enums/ENullQuery';
import { Application } from '../Entities/Application/Application';
import { SearchDto } from './common/searchDto';

export enum ApplicationCustomSort {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  AGE_ON_BOARD_MEETING_DATE = 'ageOnBoardMeetingDate',
  ACADEMIC_INSTITUTION = 'academicInstitution'
}

export class SearchApplicationDto extends SearchDto<
  Application,
  ApplicationCustomSort
> {
  candidateIds?: number[];
  boardIds?: number[];
  selectedTraining?: ETrainingTypes | NullQuery;
  sponsorUnit?: string;
  patchScoreMin?: number;
  patchScoreMax?: number;
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';

type ProgressIcon = {
  name: string;
  fill: string;
};

const USABLE_ICONS: { [key: string]: ProgressIcon } = {
  done: { name: 'checkCircleFilled', fill: '#3E66FB' },
  todo: { name: 'circleFilledMinus', fill: '#9F9FA8' },
  partial: { name: 'circlePartialFilled', fill: '#fff' }
};

@Component<ProgressTableComponent>({
  components: {
    IconComponent
  }
})
export default class ProgressTableComponent extends Vue {
  @Prop({ default: 0 })
  value!: number;

  get icon(): ProgressIcon {
    if (this.value > 99) {
      return USABLE_ICONS.done;
    }
    if (this.value < 1) {
      return USABLE_ICONS.todo;
    }
    return USABLE_ICONS.partial;
  }
}

import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import PatchScoreTableElementComponent from '@/components/Table/Elements/PatchScoreTableElementComponent.vue';
import ProgressTableComponent from '@/components/Table/Elements/ProgressTableComponent.vue';
import {
  ApplicationCustomSort,
  SearchApplicationDto
} from '@/models/Dtos/searchApplicationDto';
import { Application } from '@/models/Entities/Application/Application';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { BoardApplicationTableData } from './BoardApplicationTableData';

export const closedBoardApplicationTableConfigBuilder: TableConfigBuilder<
  BoardApplicationTableData,
  Application,
  ApplicationCustomSort,
  SearchApplicationDto
> = (): ITableConfiguration<
  BoardApplicationTableData,
  Application,
  ApplicationCustomSort
> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Last Name',
        defaultPinned: true,
        render: {
          componentRef: LinkElementComponent,
          componentProps: (rowData) => {
            return {
              displayString: rowData.lastName,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.applicantId }
              }
            };
          }
        },
        sortKey: ApplicationCustomSort.LAST_NAME
      },
      {
        render: 'firstName',
        columnTitle: 'First Name',
        defaultPinned: true,
        sortKey: ApplicationCustomSort.FIRST_NAME
      },
      {
        columnTitle: 'Progress',
        render: {
          componentRef: ProgressTableComponent,
          componentProps: (rowData) => {
            return {
              value: rowData.progress
            };
          }
        },
        defaultPinned: true
      },
      {
        columnTitle: 'Desired Training',
        render: 'desiredTraining1',
        sortKey: 'selectionPreferencesTrainingpreference1'
      },
      {
        columnTitle: 'Selected Training',
        render: 'selectedTraining',
        sortKey: 'selectedTraining'
      },
      {
        columnTitle: 'Patch Score',
        render: {
          componentRef: PatchScoreTableElementComponent,
          componentProps: (rowData) => {
            return { valueString: rowData.patchScore };
          }
        },
        sortKey: 'patchScore'
      },
      {
        columnTitle: 'Prior Svc. Rank',
        render: 'priorServiceGrade',
        sortKey: 'priorServiceGrade'
      },
      {
        columnTitle: 'Civilian Pilot Certificate',
        render: 'civilianPilotCertificate',
        sortKey: 'flyExperienceCivilianpilotcertificate'
      },
      {
        columnTitle: 'Civilian Flight Rating Category',
        render: 'civilianFlightRatingCategory',
        sortKey: 'flyExperienceCivilianflightratingcategory'
      },
      {
        columnTitle: 'Civilian Flight Rating Class',
        render: 'civilianFlightRatingClass',
        sortKey: 'flyExperienceCivilianflightratingclass'
      },
      {
        columnTitle: 'Civilian Flight Hours',
        render: 'civilianTotalFlightHours',
        sortKey: 'flyExperienceCiviliantotalflighthours'
      },
      {
        columnTitle: 'Military Flight Hours',
        render: 'flyExperienceMilitaryTotalFlightHours',
        sortKey: 'flyExperienceTotalmilitaryflighthours'
      },
      {
        columnTitle: 'Sponsor Unit',
        render: 'sponsorUnit',
        sortKey: 'sponsorshipSponsorunit'
      },
      {
        columnTitle: 'Expected Rotc Graduation Date',
        render: 'expectedRotcGraduation',
        sortKey: 'academicInformationExpectedrotcgraduation'
      },
      {
        render: 'flightPhysicalStatusAtBoard',
        columnTitle: 'Physical Status',
        sortKey: 'flightPhysicalStatusAtBoard'
      },
      {
        render: 'testingPcsm',
        columnTitle: 'PCSM Score',
        sortKey: 'testingInformationPcsmscore'
      },
      {
        render: 'testingAbm',
        columnTitle: 'ABM Score',
        sortKey: 'testingInformationAbmscore'
      },
      {
        render: 'testingCso',
        columnTitle: 'CSO Score',
        sortKey: 'testingInformationCsoscore'
      },
      {
        render: 'testingPilot',
        columnTitle: 'Pilot Score',
        sortKey: 'testingInformationPilotscore'
      },
      {
        render: 'ageOnBoardMeetingDate',
        columnTitle: 'Age on Board Meeting Date',
        sortKey: ApplicationCustomSort.AGE_ON_BOARD_MEETING_DATE
      },
      {
        render: 'academicInstitution',
        columnTitle: 'Academic Institution',
        sortKey: ApplicationCustomSort.ACADEMIC_INSTITUTION
      }
    ]
  };
};

import { ETrainingTypes } from '@/enums';
import { SearchApplicationDto } from '@/models/Dtos/searchApplicationDto';
import { IQuickFilterFilter } from '@/models/Filters/QuickFilterFilter';

export const selectedTrainingQuickFilters: IQuickFilterFilter<SearchApplicationDto>[] =
  [
    {
      label: 'Un-Selected',
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = 'NULL';
      }
    },
    {
      label: ETrainingTypes.PILOT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.PILOT;
      }
    },
    {
      label: ETrainingTypes.PILOT_ALT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.PILOT_ALT;
      }
    },
    {
      label: ETrainingTypes.ABM.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.ABM;
      }
    },
    {
      label: ETrainingTypes.ABM_ALT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.ABM_ALT;
      }
    },
    {
      label: ETrainingTypes.RPA.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.RPA;
      }
    },
    {
      label: ETrainingTypes.RPA_ALT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.RPA_ALT;
      }
    },
    {
      label: ETrainingTypes.CSO.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.CSO;
      }
    },
    {
      label: ETrainingTypes.CSO_ALT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.CSO_ALT;
      }
    },
    {
      label: ETrainingTypes.NON_SELECT.valueOf(),
      apply: (dto: SearchApplicationDto) => {
        dto.selectedTraining = ETrainingTypes.NON_SELECT;
      }
    }
  ];

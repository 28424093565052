var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c("span", [_vm._v(_vm._s(_vm.valueString))]),
      _vm._l(_vm.numCircles, function (el, idx) {
        return _c("span", {
          key: idx,
          staticClass: "w-2 h-2 ml-1 rounded-full",
          class: _vm.circleColor(_vm.numCircles - 1),
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
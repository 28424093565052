import { SearchApplicationDto } from '@/models/Dtos/searchApplicationDto';
import { NumberRange } from '@/models/NumberRange';
import {
  numberRangeFilterComponentDataBuilder,
  stringFilterComponentDataBuilder
} from '@/models/Table/Table';

export const patchScoreFilter = numberRangeFilterComponentDataBuilder(
  'Patch Score',
  (t: SearchApplicationDto, value: NumberRange) => {
    t.patchScoreMin = value.minValue;
    t.patchScoreMax = value.maxValue;
  },
  { min: 0, max: 100 }
);

export const sponsorUnitFilter = stringFilterComponentDataBuilder(
  'Sponsor Unit',
  (t: SearchApplicationDto, value: string) => {
    t.sponsorUnit = value;
  }
);

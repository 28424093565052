
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
const circleColors: string[] = [
  'bg-red-500',
  'bg-yellow-600',
  'bg-yellow-300',
  'bg-green-200',
  'bg-green-500'
];
@Component<PatchScoreTableElementComponent>({ components: { IconComponent } })
export default class PatchScoreTableElementComponent extends Vue {
  @Prop({ default: 0 })
  valueString?: number;

  circleColor(idx: number) {
    return circleColors[idx];
  }

  get numCircles() {
    const score = Math.ceil(Number(this.valueString) / 20);
    return score > 5 ? 5 : score;
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "relative w-full p-4" },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "inline-flex" }, [
            _c("h3", { staticClass: "pt-4 pl-8 mr-2 text-gray-500" }, [
              _vm._v("A3 Boards /"),
            ]),
            _c("h3", { staticClass: "pt-4 mr-2" }, [
              _vm._v("Board " + _vm._s(_vm.board.title)),
            ]),
            !_vm.isActive
              ? _c("h3", { staticClass: "mt-4 text-red-600" }, [
                  _vm._v("-- CLOSED"),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "inline-flex" },
              [
                _c("icon-component", {
                  staticClass: "mt-5 ml-8",
                  attrs: {
                    name: "calendar",
                    fill: "white",
                    width: 14,
                    height: 14,
                  },
                }),
                _vm.board.startDate && _vm.board.endDate
                  ? _c("h5", { staticClass: "pt-4 pl-2 mr-2 text-gray-500" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(_vm.board.startDate)) +
                          " - " +
                          _vm._s(_vm.formatDate(_vm.board.endDate)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("h5", { staticClass: "pt-4 font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.getDaysRemaining(_vm.board)) + " "),
                ]),
              ],
              1
            ),
          ]),
          _vm.boardApplicationSearchService
            ? _c(
                "async-table-component",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "m-8",
                      attrs: {
                        isSortable: true,
                        isColumnEditable: true,
                        isFilterable: true,
                        isQuickFilterable: true,
                        canDownloadCSV: true,
                        csvFileName: _vm.csvFileName,
                        localStorageKey: "A3-board-details-table",
                        emptyTableMessage: "0 active applicants",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "settings-right",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.board.isActive,
                                        expression: "board.isActive",
                                      },
                                    ],
                                    staticClass: "flex items-center",
                                  },
                                  [
                                    _c(
                                      "button-component",
                                      {
                                        staticClass: "mx-2 whitespace-nowrap",
                                        on: { click: _vm.closeBoardHandler },
                                      },
                                      [_vm._v("Close Board ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        94245797
                      ),
                    },
                    "async-table-component",
                    _vm.boardApplicationSearchService.tableProps,
                    false
                  ),
                  _vm.boardApplicationSearchService.tableEventListeners
                ),
                [_vm._v(" >")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.board.applications,
              expression: "!board.applications",
            },
          ],
          staticClass: "flex items-center justify-center text-gray-600",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center" },
            [
              _c("icon-component", {
                staticClass: "my-8",
                attrs: {
                  name: "airForce",
                  width: 67,
                  height: 60,
                  fill: "#E6E6EA",
                },
              }),
              _c("h4", [_vm._v("No applicants present in this board")]),
            ],
            1
          ),
        ]
      ),
      _c("yes-no-modal", { ref: "yesNoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { SearchApplicationDto } from '@/models/Dtos/searchApplicationDto';
import { Application } from '@/models/Entities/Application/Application';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { IBaseApplicationTableData } from '../BaseApplicationTableData';

export class RemoveFromBoardActionBuilder
  implements
    IEntityActionBuilder<
      IBaseApplicationTableData,
      Application,
      SearchApplicationDto
    >
{
  build(
    rowData: IBaseApplicationTableData,
    entityActionButtons: EntityActionButtons<
      IBaseApplicationTableData,
      Application,
      SearchApplicationDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'trash',
      text: '',
      iconFill: '#C81111',
      disabled: rowData.isArchived,
      actionHandler: async () => {
        const confirmed = await entityActionButtons.yesNoModal.open({
          title: `Remove ${entityActionButtons.capitalizedTableDataTypeName}?`,
          message: `Are you sure you want to remove ${entityActionButtons.tableDataTypeName}?`
        });
        if (!confirmed) {
          return;
        }
        try {
          entityActionButtons.tableSearchService.loaded = false;
          await entityActionButtons.store.dispatch(
            'applicantModule/patchApplication',
            {
              applicationId: rowData.entityId,
              payload: { board: null }
            }
          );
          await entityActionButtons.store.dispatch('snackBarModule/enqueue', {
            message: `${entityActionButtons.capitalizedTableDataTypeName} removed from board`,
            icon: 'checkGreen'
          });
          return entityActionButtons.tableSearchService.queryData();
        } catch {
          entityActionButtons.tableSearchService.loaded = true;
          await entityActionButtons.store.dispatch('snackBarModule/enqueue', {
            message: `There was an error removing the ${entityActionButtons.tableDataTypeName}`,
            icon: 'caution'
          });
        }
      }
    };
  }
}


import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import YesNoModal from '@/components/Modals/YesNoModalComponent.vue';
import {
  patchScoreFilter,
  sponsorUnitFilter
} from '@/components/Table/Application/ApplicationFilters';
import { selectedTrainingQuickFilters } from '@/components/Table/Application/ApplicationQuickFilters';
import { BoardApplicantDataProvider } from '@/components/Table/Application/BoardApplication/BoardApplicationDataProvider';
import { boardApplicationTableConfigBuilder } from '@/components/Table/Application/BoardApplication/BoardApplicationTableConfigBuilder';
import { BoardApplicationTableData } from '@/components/Table/Application/BoardApplication/BoardApplicationTableData';
import { closedBoardApplicationTableConfigBuilder } from '@/components/Table/Application/BoardApplication/ClosedBoardApplicationTableConfigBuilder';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import {
  ApplicationCustomSort,
  SearchApplicationDto
} from '@/models/Dtos/searchApplicationDto';
import { Application } from '@/models/Entities/Application/Application';
import { Board } from '@/models/Entities/Board';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { differenceInDays, format, parseISO } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<A3BoardDetailsPage>({
  components: {
    LegacyTableComponent,
    QuickFilterComponent,
    ButtonComponent,
    YesNoModal,
    IconComponent,
    AsyncTableComponent
  }
})
export default class A3BoardDetailsPage extends Vue {
  @Prop({ required: true })
  board!: Board;

  boardApplicationSearchService: TableSearchService<
    BoardApplicationTableData,
    Application,
    ApplicationCustomSort,
    SearchApplicationDto
  > | null = null;

  mounted(): void {
    this.boardApplicationSearchService = new TableSearchService<
      BoardApplicationTableData,
      Application,
      ApplicationCustomSort,
      SearchApplicationDto
    >(
      this.$store,
      new BoardApplicantDataProvider(this.$store),
      {
        boardIds: this.boardIds,
        relations: ['candidate']
      },
      { defaultSortProperty: 'id' },
      this.isActive
        ? boardApplicationTableConfigBuilder
        : closedBoardApplicationTableConfigBuilder,
      {
        filters: [patchScoreFilter, sponsorUnitFilter],
        quickFilters: selectedTrainingQuickFilters
      }
    );
    this.boardApplicationSearchService.queryData();
  }

  $refs!: {
    yesNoModal: YesNoModal;
  };

  get boardIds() {
    return [this.board.id];
  }

  getDaysRemaining(board: Board): any {
    const endDateDifference = differenceInDays(
      new Date(board.endDate),
      Date.now()
    );
    const startDateDifference = differenceInDays(
      Date.now(),
      new Date(board.startDate)
    );
    if (endDateDifference < 0) {
      return '-- ' + Math.abs(endDateDifference) + ' days since board closed';
    } else if (startDateDifference < 0) {
      return '-- ' + Math.abs(startDateDifference) + ' days until start';
    }
    return (
      '-- ' +
      differenceInDays(new Date(board.endDate), Date.now()) +
      ' days remaining'
    );
  }

  get isActive(): boolean {
    return this.board.isActive;
  }
  get csvFileName(): string {
    return `A3_Board ${this.board.title}`;
  }

  formatDate(date: string | Date): string {
    const newDate = new Date(date);
    return format(parseISO(newDate.toISOString()), 'MM/dd/yyy');
  }

  async closeBoardHandler(): Promise<void> {
    try {
      const yesNoModal = this.$refs.yesNoModal;
      const confirmed = await yesNoModal.open({
        title: 'Close Board',
        message: `Are you sure you want to close Board ${this.board.title}? This action
        cannot be undone.`
      });
      if (!confirmed) {
        return;
      }
      await this.$store.dispatch('boardModule/update', {
        id: this.board.id,
        payload: this.board,
        path: 'close'
      });
      await this.addApplicationsToGains();
      await this.$store.dispatch('snackBarModule/enqueue', {
        message: `board ${this.board.title} has been closed`
      });
      this.board.isActive = false;
    } catch (err) {
      if (err) {
        await this.$store.dispatch('snackBarModule/enqueue', {
          message: `board ${this.board.title} failed to close`
        });
      }
    }
  }

  async addApplicationsToGains(): Promise<void> {
    await this.$store.dispatch('materializedModule/create', {
      path: 'gains/gains/generate',
      dto: { closedBoardId: this.board.id }
    });
  }
}

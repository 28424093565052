import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchApplicationDto } from '@/models/Dtos/searchApplicationDto';
import { Application } from '@/models/Entities/Application/Application';
import { TableDataProvider } from '../../models/TableDataProvider';
import { BoardApplicationTableData } from './BoardApplicationTableData';

export class BoardApplicantDataProvider extends TableDataProvider<
  BoardApplicationTableData,
  Application,
  SearchApplicationDto
> {
  protected transformer = (t: Application): BoardApplicationTableData =>
    new BoardApplicationTableData(t);

  protected queryHandler = async (
    searchDto: SearchApplicationDto
  ): Promise<SearchResponseDto<Application>> => {
    const results: SearchResponseDto<Application> = await this.store.dispatch(
      'applicationModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}

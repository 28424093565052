import {
  BadgeType,
  ECivilianFlightRatingCategory,
  ECivilianFlightRatingClass,
  ECivilianPilotCertificate,
  EFlightPhysicalTypes,
  ETrainingTypes
} from '@/enums';
import { Application } from '@/models/Entities/Application/Application';
import { ApplicationProgress } from '@/models/Entities/Application/Constants';
import { ITableData } from '../../models/TableData';

export class BoardApplicationTableData implements ITableData {
  entityId: number;
  progress: number;
  selectedTraining!: ETrainingTypes | null;
  firstName?: string;
  lastName?: string;
  applicantId?: number;
  ageOnBoardMeetingDate?: number | null;
  desiredTraining1!: BadgeType;
  patchScore!: number;
  sponsorUnit!: string | null;
  testingPilot!: number | null;
  testingCso!: number | null;
  testingAbm!: number | null;
  testingPcsm!: number | null;
  priorServiceGrade!: string | null;
  expectedRotcGraduation!: Date | null;
  flyExperienceMilitaryTotalFlightHours!: number | null;
  civilianTotalFlightHours!: number | null;
  civilianPilotCertificate!: ECivilianPilotCertificate | null;
  civilianFlightRatingCategory!: ECivilianFlightRatingCategory | null;
  civilianFlightRatingClass!: ECivilianFlightRatingClass | null;
  flightPhysicalStatusAtBoard!: EFlightPhysicalTypes | null;
  applicationId!: number;
  academicInstitution?: string | null;
  isArchived!: boolean;

  constructor(application: Application) {
    this.entityId = application.id;
    const applicantProgress: ApplicationProgress = application.progress;
    this.progress = Math.round(
      (applicantProgress.completed / applicantProgress.total) * 100
    );
    // some candidates are null in P1
    if (application.candidate) {
      this.firstName =
        application.candidate.identificationInformationFirstname ?? '';
      this.lastName =
        application.candidate.identificationInformationLastname ?? '';
      this.applicantId = application.candidate.id;
      // fix
      this.ageOnBoardMeetingDate = application.candidate.age;
      this.academicInstitution =
        application.candidate.academicInformationInstitution;
    }

    this.selectedTraining = application.selectedTraining;
    this.desiredTraining1 = application.selectionPreferencesTrainingpreference1;
    this.patchScore = application.patchScore;
    this.sponsorUnit = application.sponsorshipSponsorunit;
    this.expectedRotcGraduation =
      application.academicInformationExpectedrotcgraduation;
    this.applicationId = application.id;
    this.priorServiceGrade = application.priorServiceGrade;
    this.flyExperienceMilitaryTotalFlightHours =
      application.flyExperienceTotalmilitaryflighthours;
    this.civilianTotalFlightHours =
      application.flyExperienceCiviliantotalflighthours;
    this.civilianPilotCertificate =
      application.flyExperienceCivilianpilotcertificate;
    this.civilianFlightRatingCategory =
      application.flyExperienceCivilianflightratingcategory;
    this.civilianFlightRatingClass =
      application.flyExperienceCivilianflightratingclass;
    this.testingPilot = application.testingInformationPilotscore;
    this.testingCso = application.testingInformationCsoscore;
    this.testingAbm = application.testingInformationAbmscore;
    this.testingPcsm = application.testingInformationPcsmscore;
    this.flightPhysicalStatusAtBoard = application.flightPhysicalStatusAtBoard;
    this.isArchived = application.isArchived;
  }
}

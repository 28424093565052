
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DropdownOptions, FormOptionValue } from '@/models/Forms/FormOption';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';

@Component<DropdownTableElementComponent>({
  components: {
    DropdownComponent
  }
})
export default class DropdownTableElementComponent extends Vue {
  @Prop({
    default: () => []
  })
  values!: DropdownOptions[];

  @Prop()
  dropDownValue?: FormOptionValue;

  @Prop()
  changeHandler!: (value: string | DropdownOptions) => void;
}
